import Api from '@/services/Index';
import axios from 'axios';


const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/requests/list',config);
}
const show = async (id, config) => {
    const baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return axios.get(`${baseURL}housing/requests/detail/${id}`, config);
};
const getAllAdmin = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/housing/requests',config);
}
const showAdmin = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/housing/requests/${id}`);
}
const downloadFile = async (uuid) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/housing/requests/download/${uuid}`, {responseType:'arraybuffer'});
}
const changeStatusRequest = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;

    return Api.post(`/housing/requests/${id}/change-status`,formData);
}
const addExpenseDetail = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let config={
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    return Api.post(`/housing/requests/add-expense-detail/${id}`,formData,config);
}

export default {
    getAll,
    show,
    getAllAdmin,
    showAdmin,
    downloadFile,
    changeStatusRequest,
    addExpenseDetail
}
